import React, { useEffect, useState } from 'react';
import ProgressBar from '../ProgressBar/ProgressBar';
import { Columns, Content, Heading } from 'react-bulma-components';
import CEIntroduction from './CEIntroduction';
import CEQuestion1 from './CEQuestion1';
import CEQuestion2 from './CEQuestion2';
import CEQuestion3 from './CEQuestion3';
import CEQuestion4 from './CEQuestion4';
import CEQuestion5 from './CEQuestion5';
import CEQuestion6 from './CEQuestion6';
import CEQuestion7 from './CEQuestion7';
import CEQuestion8 from './CEQuestion8';
import CESubmit from './CESubmit';

const CyberEvaluation = ({progress,  setProgress,  processId, evaluationResults, setEvaluationResults}) => {
  const currentProgress = (((progress -1) / 4) * 100);

  const [qController, setQController] = useState(
    {
      display: {
        q1: true,
        q2: false,
        q3: false,
        q4: false,
        q5: false,
        q6: false,
        q7: false,
        q8: false,
        submit: false,
      },
      responses: {
        q1: null,
        q2: null,
        q3: null,
        q4: null,
        q5: null,
        q6: null,
        q7: null,
        q8: null,
      },
      score: {
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0,
        q5: 0,
        q6: 0,
        q7: 0,
        q8: 0,
      }
    }
  )
  // console.log('CE qController', qController);

  useEffect(() => {
    const element = document.getElementById('pagetop');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);


  return (
    <Columns className="m-1">
      <Columns.Column>
        <Content>
          <ProgressBar progress={currentProgress} />
          <Heading weight={"semibold"} size={2} className="is-size-4-mobile">ICT Strategy and Governance</Heading>
          <Heading renderAs="h2" weight={"normal"} size={4} subtitle={true} className="is-size-5-mobile mb-0">Director Skills Assessment</Heading>
          <Heading renderAs="h3" weight={"normal"} size={6} subtitle={true} className="is-size-7-mobile mt-1">8 Questions</Heading>
          <CEIntroduction />
          {qController.display.q1 && <CEQuestion1 qController={qController} setQController={setQController} />}
          {qController.display.q2 && <CEQuestion2 qController={qController} setQController={setQController} />}
          {qController.display.q3 && <CEQuestion3 qController={qController} setQController={setQController} />}
          {qController.display.q4 && <CEQuestion4 qController={qController} setQController={setQController} />}
          {qController.display.q5 && <CEQuestion5 qController={qController} setQController={setQController} />}
          {qController.display.q6 && <CEQuestion6 qController={qController} setQController={setQController} />}
          {qController.display.q7 && <CEQuestion7 qController={qController} setQController={setQController} />}
          {qController.display.q8 && <CEQuestion8 qController={qController} setQController={setQController} />}
          {qController.display.submit && <CESubmit qController={qController} evaluationResults={evaluationResults} setEvaluationResults={setEvaluationResults} progress={progress} processId={processId} setProgress={setProgress} />}
        </Content>
      </Columns.Column>
    </Columns>
  )
}

export default CyberEvaluation;