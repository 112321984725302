import { gql } from '@apollo/client';

export const INSERT_ONE_RESPONSE = gql`
  mutation InsertOneResponse($data: ResponseInsertInput!) {
    insertOneResponse(data: $data) {
      _id
    }
  }
`;

export const UPDATE_ONE_RESPONSE_DETAILS = gql`
  mutation UpdateOneResponse($query: ResponseQueryInput!, $set: ResponseUpdateInput!) {
    updateOneResponse(query: $query, set: $set) {
      _id
      contact {
        first_name
        last_name
        email
        mobile
        organisation
        jobtitle
        governance_role
        sector
      }
    }
  }
`;

export const UPDATE_ONE_RESPONSE_GWQUALIFY = gql`
  mutation UpdateOneResponse($query: ResponseQueryInput!, $set: ResponseUpdateInput!) {
    updateOneResponse(query: $query, set: $set) {
      _id
      governwith_qualifiers {
        question_1
        question_yes_1
        question_yes_2
        question_no_1
      }
    }
  }
`;

export const UPDATE_ONE_RESPONSE_CONQUALIFY = gql`
  mutation UpdateOneResponse($query: ResponseQueryInput!, $set: ResponseUpdateInput!) {
    updateOneResponse(query: $query, set: $set) {
      _id
      convene_qualifiers {
        question_1
        question_yes_1
        question_yes_2
        question_no_1
      }
    }
  }
`;

export const UPDATE_ONE_RESPONSE_CYBERUPDATE = gql`
  mutation UpdateOneResponse($query: ResponseQueryInput!, $set: ResponseUpdateInput!) {
    updateOneResponse(query: $query, set: $set) {
      _id
      cyber_responses {
        question_1
        question_2
        question_3
        question_4
        question_5
        question_6
        question_7
        question_8
      }
      cyber_results {
        question_1
        question_2
        question_3
        question_4
        question_5
        question_6
        question_7
        question_8
        skills
        experience
        qualifications
        total
      }
    }
  }
`;

export const UPDATE_ONE_RESPONSE_ESGUPDATE = gql`
  mutation UpdateOneResponse($query: ResponseQueryInput!, $set: ResponseUpdateInput!) {
    updateOneResponse(query: $query, set: $set) {
      _id
      esg_responses {
        question_1
        question_2
        question_3
      }
      esg_results {
        question_1
        question_2
        question_3
        skills
        experience
        qualifications
        total
      }
    }
  }
`;

export const UPDATE_ONE_RESPONSE_RESULTUPDATE = gql`
  mutation UpdateOneResponse($query: ResponseQueryInput!, $set: ResponseUpdateInput!) {
    updateOneResponse(query: $query, set: $set) {
      _id
      esg_responses {
        question_1
        question_2
        question_3
      }
      esg_results {
        question_1
        question_2
        question_3
        skills
        experience
        qualifications
        total
      }
    }
  }
`;