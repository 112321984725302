import React, { useRef } from 'react';
import { Box, Form } from 'react-bulma-components';

const RadioWithClickableBox = ({ value, selectedValue, onChange, name, children }) => {
  const handleLabelClick = () => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <label onClick={handleLabelClick}>
      <Box className={selectedValue === value ? "p-3 has-background-grey-lighter" : "p-3 has-background-white-bis"}>
        <Form.Radio
          value={value}
          name={name}
          checked={selectedValue === value}
          onChange={() => {}} // Empty onChange handler for the radio button
        >
          &nbsp;{children}
        </Form.Radio>
      </Box>
    </label>
  );
}


export default RadioWithClickableBox;