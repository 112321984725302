import React, { useState, useEffect } from 'react';
import { Block, Columns, Form, Heading } from 'react-bulma-components';
import RadioWithClickableBox from '../Misc/RadioWithClickableBox';

const CEQuestion8 = ({qController, setQController}) => {
  const [selectedValue, setSelectedValue] = useState(null);

  // scroll our question into view
  useEffect(() => {
    const element = document.getElementById('cequestion8');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const onChange = (value) => {
    setSelectedValue(value);
    let updatedController = { ...qController };

    // record the response
    updatedController.responses.q8 = value;
    // determine score
    switch(value) {
      case 'Led Project':
        updatedController.score.q8 = 100;
        break;
      case 'Participated in Project':
        updatedController.score.q8 = 50;
        break;
      default:
        updatedController.score.q8 = 0;
    }
    updatedController.display.submit = true;
    setQController(updatedController);
  }

  return (
    <>
      <Block>
        <Heading className="is-size-4 mb-2">
          <a name="cequestion8" id="cequestion8">Question 8.</a>
        </Heading>
        <Block className="mb-2 has-text-weight-medium">
          Have you had involvement in the delivery, development and/or implementation of ICT projects (e.g. electronic medical records systems, eHealth) that also consider digital interoperability?
        </Block>
        <Columns className="m-0 mb-1">
          <Columns.Column className="m-0 p-1 pb-2">
            <RadioWithClickableBox value="No Involvement" selectedValue={selectedValue} onChange={onChange} name="CEQuestion8">
              No Involvement
            </RadioWithClickableBox>
          </Columns.Column>
          <Columns.Column className="m-0 p-1 pb-2">
            <RadioWithClickableBox value="Participated in Project" selectedValue={selectedValue} onChange={onChange} name="CEQuestion8">
              Participated in Project
            </RadioWithClickableBox>
          </Columns.Column>
          <Columns.Column className="m-0 p-1 pb-2">
            <RadioWithClickableBox value="Led Project" selectedValue={selectedValue} onChange={onChange} name="CEQuestion8">
              Led Project
            </RadioWithClickableBox>
          </Columns.Column>
        </Columns>
      </Block>
      <hr />
    </>
  )
}

export default CEQuestion8;