import React, { useEffect } from 'react';
import { Block } from 'react-bulma-components';

const CEIntroduction = () => {
  // scroll our question into view
  return (
    <>
      <Block className="is-italic">
        Definition: Knowledge and experience in information and communication technology (ICT), with sound knowledge of the changing IT landscapes, including Cyber Risk Management and how this can impact the sector and organisation. An understanding of data governance and its impact on privacy, research and ICT.
      </Block>
      <Block>
        The skills, qualifications and experience that are advantageous for a Board role are:
        <ul>
          <li>Expertise in managing information and communications technology, particularly oversight of substantial IT programmes, and knowledge of IT governance, including privacy, data management and security (including cyber security).</li>
          <li>Having overseen or been involved in the oversight, development, rollout and/or maintenance of enterprise systems (for example relating to records management), digital strategies, and security infrastructure.</li>
        </ul>
      </Block>
      <hr />
    </>
  )
}

export default CEIntroduction;