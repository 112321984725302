import React from 'react';
import { Columns } from 'react-bulma-components';

const Footer = () => {

  // const resetForm = () => {
  //   if (localStorage.getItem('currentEvaluationResults')) {
  //     localStorage.removeItem('currentEvaluationResults');
  //   }
  //   if (localStorage.getItem('currentProgressId')) {
  //     localStorage.removeItem('currentProgressId');
  //   }
  //   if (localStorage.getItem('currentProcessId')) {
  //     localStorage.removeItem('currentProcessId');
  //   }
  //
  //   // Reload the page
  //   window.location.reload();
  // }



  return (
    <Columns className="m-0 mt-1">
      <Columns.Column className="has-background-white-ter pt-5 pb-5">
        <Columns className="m-0 mt-1 is-mobile">
          <Columns.Column>
            &copy; <a href="https://governwith.com" target="_blank" rel="noreferrer">GovernWith</a> 2023
          </Columns.Column>
          {/*<Columns.Column className="has-text-right">*/}
          {/*  <a onClick={resetForm}>Reset Form</a>*/}
          {/*</Columns.Column>*/}
        </Columns>
      </Columns.Column>
    </Columns>
  )
}

export default Footer;