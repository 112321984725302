import React, { useState, useEffect } from 'react';
import { Block, Columns, Form, Heading } from 'react-bulma-components';
import RadioWithClickableBox from '../Misc/RadioWithClickableBox';

const CEQuestion7 = ({qController, setQController}) => {
  const [selectedValue, setSelectedValue] = useState(null);

  // scroll our question into view
  useEffect(() => {
    const element = document.getElementById('cequestion7');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const onChange = (value) => {
    setSelectedValue(value);
    let updatedController = { ...qController };

    // record the response
    updatedController.responses.q7 = value;
    // determine score
    switch(value) {
      case '11 plus yrs':
        updatedController.score.q7 = 100;
        break;
      case '6 to 10 yrs':
        updatedController.score.q7 = 75;
        break;
      case '3 to 5 yrs':
        updatedController.score.q7 = 50;
        break;
      case '0 to 2 yrs':
        updatedController.score.q7 = 25;
        break;
      default:
        updatedController.score.q7 = 0;
    }
    updatedController.display.q8 = true;
    setQController(updatedController);
  }

  return (
    <>
      <Block>
        <Heading className="is-size-4 mb-2">
          <a name="cequestion7" id="cequestion7">Question 7.</a>
        </Heading>
        <Block className="mb-2 has-text-weight-medium">
          Do you have relevant experience in the field of ICT, Data Governance or Cyber Risk?
        </Block>
        <Columns className="m-0 mb-1">
          <Columns.Column className="m-0 p-1 pb-2">
            <RadioWithClickableBox value="No Experience" selectedValue={selectedValue} onChange={onChange} name="CEQuestion7">
              No Experience
            </RadioWithClickableBox>
          </Columns.Column>
          <Columns.Column className="m-0 p-1 pb-2">
            <RadioWithClickableBox value="0 to 2 yrs" selectedValue={selectedValue} onChange={onChange} name="CEQuestion7">
              0 to 2 yrs
            </RadioWithClickableBox>
          </Columns.Column>
          <Columns.Column className="m-0 p-1 pb-2">
            <RadioWithClickableBox value="3 to 5 yrs" selectedValue={selectedValue} onChange={onChange} name="CEQuestion7">
              3 to 5 yrs
            </RadioWithClickableBox>
          </Columns.Column>
          <Columns.Column className="m-0 p-1 pb-2">
            <RadioWithClickableBox value="6 to 10 yrs" selectedValue={selectedValue} onChange={onChange} name="CEQuestion7">
              6 to 10 yrs
            </RadioWithClickableBox>
          </Columns.Column>
          <Columns.Column className="m-0 p-1 pb-2">
            <RadioWithClickableBox value="11 plus yrs" selectedValue={selectedValue} onChange={onChange} name="CEQuestion7">
              11 plus yrs
            </RadioWithClickableBox>
          </Columns.Column>
        </Columns>
      </Block>
      <hr />
    </>
  )
}

export default CEQuestion7;