import React from 'react';
import { Block } from 'react-bulma-components';

const ESGIntroduction = () => {
  return (
    <>
      <Block className="is-italic">
        Definition: The role of a governing body in relation to climate change, extreme events, social inclusion, gender equity, modern slavery, working with Aboriginals and Torres Strait Islanders
        is to firstly ensure that they understand these risks and how they impact on their organisation. Secondly, they must ensure that the organisations strategic direction, policies and procedures,
        and behaviours reflect the right culture for addressing these issues well. This is achieved through their oversight role of mentoring, analysing and feeding back. Of equal importance is the
        governing bodies ability to look around the room to reflect on their own leadership and role modelling for these important contemporary risks.
      </Block>
      <hr />
    </>
  )
}

export default ESGIntroduction;