import React, { useEffect } from 'react';
import { Block, Button, Columns, Form } from 'react-bulma-components';
import { useMutation } from '@apollo/client';
import { UPDATE_ONE_RESPONSE_GWQUALIFY } from '../GraphQL/mutations';

const QualifySubmit = ({progress, responses, setProgress, processId}) => {
  const [updateResponse, {loading}] = useMutation(UPDATE_ONE_RESPONSE_GWQUALIFY);

  useEffect(() => {
    const element = document.getElementById('gwnextbutton');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const saveAnswers = async () => {
    try {
      const { error, data } = await updateResponse({
        variables: {
          query: { _id: processId }, // The query condition
          set: {
            governwith_qualifiers: {
              question_1: responses.q1,
              question_yes_1: responses.q1a1,
              question_yes_2: responses.q1a2,
              question_no_1: responses.q1b1
            },
          },
        },
      });
      // Handle success or display a message
      if (data !== undefined && data !== null) {
        const nextProgress = progress + 1;
        localStorage.setItem('currentProgressId',parseInt(nextProgress,10));
        setProgress(parseInt(nextProgress,10));
      }
    } catch (error) {
      console.error('Error updating response:', error);
      // Handle error or display an error message
    }
  }

  return(
    <Block className="has-text-right">
      <Columns className="m-0 mb-1 is-mobile">
        <Columns.Column className="has-text-left">
          {loading ? "Saving Response..." : ""}
        </Columns.Column>
        <Columns.Column className="pr-0">
          <Form.Field>
            <Form.Control>
              <Button
                color={loading ? "light" : "primary"}
                type="submit"
                disabled={loading}
                onClick={saveAnswers}
                id="gwnextbutton"
              >
                Next &gt;&gt;
              </Button>
            </Form.Control>
          </Form.Field>
        </Columns.Column>
      </Columns>
    </Block>
  )
}
export default QualifySubmit;
