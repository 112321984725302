import React, { useState } from 'react';
import Header from '../Header/Header';
import StartForm from '../StartForm/StartForm';
import DetailsForm from '../DetailsForm/DetailsForm';
import GWQualifyForm from '../GWQualifyForm/GWQualifyForm';
// import ConQualifyForm from '../ConQualifyForm/ConQualifyForm';
import CyberEvaluation from '../CyberEvaluation/CyberEvaluation';
import ESGEvaluation from '../ESGEvaluation/ESGEvaluation';
import Results from '../Results/Results';
import Footer from '../Footer/Footer';

const ProgressController = () => {
  const storedProcessId = localStorage.getItem('currentProcessId');
  const storedProgressId = localStorage.getItem('currentProgressId');
  const storedEvaluationResults = localStorage.getItem('currentEvaluationResults');

  const [processId, setProcessId] = useState(
    storedProcessId !== null ? storedProcessId : null
  );
  const [currentComponent, setCurrentComponent] = useState(
    storedProgressId !== null ? parseInt(storedProgressId,10) : 0
  );
  const [evaluationResults, setEvaluationResults] = useState(
    storedEvaluationResults !== null ? JSON.parse(storedEvaluationResults) : { cyber: 0, esg: 0 }
  );

  // console.log("currentComponent: ", currentComponent);
  // console.log("processId: ", processId);
  // console.log("evaluationResults: ", evaluationResults);

  return (
    <>
      <Header />
      {currentComponent === 0 && <StartForm progress={currentComponent} setProgress={setCurrentComponent} setProcessId={setProcessId} />}
      {currentComponent === 1 && <DetailsForm progress={currentComponent} setProgress={setCurrentComponent} processId={processId} />}
      {currentComponent === 2 && <GWQualifyForm progress={currentComponent} setProgress={setCurrentComponent} processId={processId} />}
      {/* {currentComponent === 2 && <ConQualifyForm progress={currentComponent} setProgress={setCurrentComponent} processId={processId} />} */}
      {currentComponent === 3 && <CyberEvaluation progress={currentComponent} setProgress={setCurrentComponent} processId={processId} evaluationResults={evaluationResults} setEvaluationResults={setEvaluationResults} />}
      {currentComponent === 4 && <ESGEvaluation progress={currentComponent} setProgress={setCurrentComponent} processId={processId} evaluationResults={evaluationResults} setEvaluationResults={setEvaluationResults} />}
      {currentComponent === 5 && <Results progress={currentComponent} evaluationResults={evaluationResults} processId={processId} />}
      <Footer />
    </>
  )
}

export default ProgressController;