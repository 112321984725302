import 'bulma/css/bulma.css';
import RealmApolloProvider from './RealmApolloProvider/RealmApolloProvider';
import ProgressController from './ProcessController/ProgressController';

function App() {
  return (
    <RealmApolloProvider>
      <ProgressController />
    </RealmApolloProvider>
  );
}

export default App;
