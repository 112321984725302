import React, { useState } from 'react';
import { Block, Columns, Form } from 'react-bulma-components';
import RadioWithClickableBox from '../Misc/RadioWithClickableBox';

const QualifyQ1B1 = ({qController, setQController}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const onChange = (value) => {
    setSelectedValue(value);
    let updatedController = { ...qController };
    updatedController.responses.q1b1 = value;
    updatedController.display.submit= true;
    setQController(updatedController);
  }

  return (
    <Block>
      <Block className="mb-2 has-text-weight-medium">
        Do you need to complete a review in next:
      </Block>
      <Columns className="m-0 mb-1">
        <Columns.Column className="p-1 pb-2">
          <RadioWithClickableBox value="Three Months" selectedValue={selectedValue} onChange={onChange} name="QualifyQ1B2">
            Three Months
          </RadioWithClickableBox>
        </Columns.Column>
        <Columns.Column className="p-1 pb-2">
          <RadioWithClickableBox value="Six Months" selectedValue={selectedValue} onChange={onChange} name="QualifyQ1B2">
            Six Months
          </RadioWithClickableBox>
        </Columns.Column>
        <Columns.Column className="p-1 pb-2">
          <RadioWithClickableBox value="By the next AGM" selectedValue={selectedValue} onChange={onChange} name="QualifyQ1B2">
            By the next AGM
          </RadioWithClickableBox>
        </Columns.Column>
        <Columns.Column className="p-1 pb-2">
          <RadioWithClickableBox value="Not Sure" selectedValue={selectedValue} onChange={onChange} name="QualifyQ1B2">
            Not Sure
          </RadioWithClickableBox>
        </Columns.Column>
      </Columns>
      <hr />
    </Block>
  )
}

export default QualifyQ1B1;