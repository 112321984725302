import React, { useEffect } from 'react';
import { Columns, Content, Block, Box,  Heading } from 'react-bulma-components';
import { useMutation } from '@apollo/client';
import { UPDATE_ONE_RESPONSE_RESULTUPDATE } from '../GraphQL/mutations';

const Results = ({ evaluationResults,  processId }) => {
  const [updateResponse] = useMutation(UPDATE_ONE_RESPONSE_RESULTUPDATE);
  // console.log(evaluationResults);

  useEffect(() => {
    const element = document.getElementById('pagetop');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const updateData = async () => {
    try {
      const { error, data } = await updateResponse({
        variables: {
          query: { _id: processId }, // The query condition
          set: {
            results_updated: false
          },
        },
      });
      // Handle success or display a message based on 'data' or 'error'
    } catch (error) {
      console.error('Error updating response:', error);
      // Handle error or display an error message
    }
  };

  useEffect(() => {
    updateData()
  }, []);

  const resetForm = () => {
    if (localStorage.getItem('currentEvaluationResults')) {
      localStorage.removeItem('currentEvaluationResults');
    }
    if (localStorage.getItem('currentProgressId')) {
      localStorage.removeItem('currentProgressId');
    }
    if (localStorage.getItem('currentProcessId')) {
      localStorage.removeItem('currentProcessId');
    }

    // Reload the page
    window.location.reload();
  }

  const cyberResult = Math.round(evaluationResults.cyber);
  const esgResult = Math.round(evaluationResults.esg);

  const determineRating = (score) => {
    if (score < 20) {
      return (
        <Block className="m-0 p-0 has-text-devfoundational">
          Developing Foundational
        </Block>
      )
    } else if (score < 25) {
      return (
        <Block className="m-0 p-0 has-text-foundational">
          Foundational
        </Block>
      )
    } else if (score < 50) {
      return (
        <Block className="m-0 p-0 has-text-devproficient">
          Developing Proficient
        </Block>
      )
    } else if (score < 75) {
      return (
        <Block className="m-0 p-0 has-text-proficient">
          Proficient
        </Block>
      )
    }  else if (score < 91) {
      return (
        <Block className="m-0 p-0 has-text-devadvanced">
          Developing Advanced
        </Block>
      )
    } else {
      return (
        <Block className="m-0 p-0 has-text-advanced">
          Advanced
        </Block>
      )
    }
  }

  const definitions = (score) => {
    if (score < 20) {
      return (
        <Block>
          Directors rated &quot;Developing Foundational&quot; may have minimal understanding, experience and qualifications relevant to the measured topic. However, the assessment process has determined that opportunities exist for the Director to improve their knowledge for applying that understanding in the setting of the Boardroom.
        </Block>
      )
    } else if (score < 25) {
      return (
        <Block>
          Directors rated as &quot;Foundational&quot; may have limited understanding experience and qualifications pertinent to the measured topic. They have a basic foundation knowledge of the topic and can apply their understanding, experience and qualifications in the setting of the Boardroom, however opportunities exist to improve their understanding and therefore capabilities.
        </Block>
      )
    } else if (score < 50) {
      return (
        <Block>
          Directors rated &quot;Developing Proficient&quot; may have some understanding, experience and qualifications pertinent to the measured topic. They have base knowledge to apply and can contribute to discussions in the Boardroom. Opportunities  exist to improve their understanding and therefore capabilities around this topic.
        </Block>
      )
    } else if (score < 75) {
      return (
        <Block>
          Directors rated &quot;Proficient&quot; have good understanding and often good experience and some qualifications pertinent to the measured topic. They have enough knowledge to apply their experience and qualifications and contribute effectively to discussions in the Boardroom. Opportunities still exist to improve their capabilities for this topic if they wish or need to become more expert.
        </Block>
      )
    }  else if (score < 91) {
      return (
        <Block>
          Directors rated &quot;Developing Advanced&quot; have very good understanding, experience and qualifications pertinent to the measured topic. They have the knowledge to apply their experience and qualifications and contribute effectively to discussions in the Boardroom. Opportunities exist to continue to evolve their capabilities for this topic if they wish to become more expert. This is a level of capability to consider having a leadership role on the Board for this topic, for example as a Board Sub Committee Chair.
        </Block>
      )
    } else {
      return (
        <Block>
          Directors rated &quot;Advanced&quot; have significant understanding, experience and high-level qualifications pertinent to the measured topic. They have the knowledge to apply their experience and qualifications and contribute effectively and as an expert to discussions in the Boardroom. This is a good level of capability to have a leadership role on the Board for this topic, for example as a Board Sub Committee Chair.
        </Block>
      )
    }
  }


  return (
    <Columns className="m-1">
      <Columns.Column>
        <Content>
          <Heading weight={"semibold"} size={2} className="is-size-4-mobile">Congratulations, your assessment results are ready!</Heading>
          <Block>
            The GovernWith process calculates a governance capability score for each assessed module in its result offering. This score then helps assign a rating that offers direction for Boards and Directors regarding their advancement in governance capabilities and their potential to make impactful contributions in a more effective manner.
          </Block>
          <Block>
            As a reference, we analyse assessment outcomes using GovernWith's Effective Practice guidelines, along with our extensive years of governance experience and corroborating data. This analysis indicates that Directors with a capability measurement exceeding 25% possess the requisite experience and qualifications, which, when combined with their corporate governance skills, enable them to make valuable contributions. Moreover, there is still room for growth and enhancement in their capabilities.
          </Block>
          <Block>
            Directors whose capability measurement is 50% or higher are regarded as proficient and can make effective contributions to the Board.
          </Block>
          <Block>
            When capability measurements are lower, it indicates a opportunity for focused capability development in those areas.
          </Block>
          <hr />
          <Heading weight={"semibold"} size={2} className="is-size-4-mobile">Your Assessment Results</Heading>

          <Heading renderAs="h2" weight={"semibold"} size={4} className="is-size-5-mobile">ICT Strategy and Governance</Heading>
          <Box className="has-background-white-ter">
            <Columns className="m-1">
              <Columns.Column className="has-text-centered p-0">
                <Heading renderAs="h4" weight={"normal"} size={4} className="is-size-5-mobile mb-1">
                  Your Governance Capability Measurement
                </Heading>
                <Block className="is-size-1 has-text-weight-bold has-text-terracotta p-0 pb-4">
                  {cyberResult}%
                </Block>
              </Columns.Column>
              <Columns.Column className="has-text-centered p-0">
                <Heading renderAs="h4" weight={"normal"} size={4} className="is-size-5-mobile mb-1">
                  Your Rating
                </Heading>
                <Block className="is-size-4 has-text-weight-semibold pb-4">
                  {determineRating(cyberResult)}
                </Block>
              </Columns.Column>
            </Columns>
          </Box>
          <Heading renderAs="h3" weight={"normal"} size={5} className="is-size-5-mobile">What does this mean?</Heading>
          <Block>
            {definitions(cyberResult)}
          </Block>

          <Heading renderAs="h3" weight={"normal"} size={5} className="is-size-5-mobile">How do I compare to everyone else?</Heading>
          <Box className="has-background-white-ter">
            <Columns className="m-1 mb-0">
              <Columns.Column className="has-text-centered p-0">
                <Heading renderAs="h4" weight={"normal"} size={4} className="is-size-5-mobile mb-1">
                  Average Director Capability Measurement
                </Heading>
                <Block className="is-size-1 has-text-weight-bold has-text-azure pb-4">
                  25.8%
                </Block>
              </Columns.Column>
              <Columns.Column className="has-text-centered p-0">
                <Heading renderAs="h4" weight={"normal"} size={4} className="is-size-5-mobile mb-1">
                  Average Director Rating
                </Heading>
                <Block className="is-size-4 has-text-weight-semibold has-text-primary pb-4">
                  <Block className="m-0 p-0 has-text-devproficient">
                    Developing Proficient
                  </Block>
                </Block>
              </Columns.Column>
            </Columns>
            <Block className="is-size-7 mt-0 has-text-centered">
              Our Average Director capabilty measurement and rating is taken from all Directors completing our Director Skills assessment in the 2022 - 2023 evaluation period.
            </Block>
          </Box>
          <Heading renderAs="h3" weight={"normal"} size={5} className="is-size-5-mobile">What can I do to expand my governance capabilities in ICT Strategy and Governance?</Heading>
          <Block>
            As a free resource, our latest series of Webinars discusses everything you need to know from a Board level perspective about understanding the threat Cyber Security poses and what steps you can take to mitigate them.
          </Block>
          <Block className="has-text-centered">
            <a className="button has-background-primary" href="https://governwith.com/knowledge/cyber-security-governance-resources?utm_campaign=sample_assessment&utm_medium=application&utm_source=assess_governwith_com" target="_blank" rel="noreferrer" >Click here to view these Webinars</a>
          </Block>
          <hr />

          <Heading renderAs="h2" weight={"semibold"} size={4} className="is-size-5-mobile">Environmental, Social and Governance (ESG)</Heading>
          <Box className="has-background-white-ter">
            <Columns className="m-1">
              <Columns.Column className="has-text-centered p-0">
                <Heading renderAs="h4" weight={"normal"} size={4} className="is-size-5-mobile mb-1">
                  Your Governance Capability Measurement
                </Heading>
                <Block className="is-size-1 has-text-weight-bold has-text-terracotta p-0 pb-4">
                  {esgResult}%
                </Block>
              </Columns.Column>
              <Columns.Column className="has-text-centered p-0">
                <Heading renderAs="h4" weight={"normal"} size={4} className="is-size-5-mobile mb-1">
                  Your Rating
                </Heading>
                <Block className="is-size-4 has-text-weight-semibold pb-4">
                  {determineRating(esgResult)}
                </Block>
              </Columns.Column>
            </Columns>
          </Box>
          <Heading renderAs="h3" weight={"normal"} size={5} className="is-size-5-mobile">What does this mean?</Heading>
          <Block>
            {definitions(esgResult)}
          </Block>

          <Heading renderAs="h3" weight={"normal"} size={5} className="is-size-5-mobile">How do I compare to everyone else?</Heading>
          <Box className="has-background-white-ter">
            <Columns className="m-1 mb-0">
              <Columns.Column className="has-text-centered p-0">
                <Heading renderAs="h4" weight={"normal"} size={4} className="is-size-5-mobile mb-1">
                  Average Director Capability Measurement
                </Heading>
                <Block className="is-size-1 has-text-weight-bold has-text-azure pb-4">
                  37.1%
                </Block>
              </Columns.Column>
              <Columns.Column className="has-text-centered p-0">
                <Heading renderAs="h4" weight={"normal"} size={4} className="is-size-5-mobile mb-1">
                  Average Director Rating
                </Heading>
                <Block className="is-size-4 has-text-weight-semibold has-text-primary pb-4">
                  <Block className="m-0 p-0 has-text-devproficient">
                    Developing Proficient
                  </Block>
                </Block>
              </Columns.Column>
            </Columns>
            <Block className="is-size-7 mt-0 has-text-centered">
              Our Average Director capabilty measurement and rating is taken from all Directors completing our Director Skills assessment in the 2022 - 2023 evaluation period.
            </Block>
          </Box>
          <Heading renderAs="h3" weight={"normal"} size={5} className="is-size-5-mobile">What can I do to expand my governance capabilities in Environmental, Social and Governance (ESG)?</Heading>
          <Block>
            As a free resource, we recently hosted a GovernWith Webinar on Climate Change Governance Risks: Navigating the Future with our guest presenter Neil Plummer. In this Webinar you can learn how to mitigate and manage the climate risks to your Strategic Plan.
          </Block>
          <Block className="has-text-centered">
            <a className="button has-background-primary" href="https://governwith.com/webinars/climate-change-governances-risks-to-the-boardroom?utm_campaign=sample_assessment&utm_medium=application&utm_source=assess_governwith_com" target="_blank" rel="noreferrer" >Click here to view this Webinar</a>
          </Block>
          <hr />
          <Heading renderAs="h2" weight={"semibold"} size={4} className="is-size-5-mobile">Like to know more about how GovernWith can assure your Board, Director and Executive governance capabilities?</Heading>
          <Block>
            We are available to demonstrate our solution Boardroom Plus and how it can instantly assist with:
            <ol>
              <li>Board Governance Review</li>
              <li>Director and Executive Governance Capability Development</li>
              <li>Board Transition and Succession</li>
            </ol>
          </Block>
          <Block className="has-text-centered">
            <a className="button has-background-primary" href="https://governwith.com/demo?utm_campaign=sample_assessment&utm_medium=application&utm_source=assess_governwith_com" target="_blank" rel="noreferrer" >Click here to book a demonstration today!</a>
          </Block>
          <Block className="has-text-centered">
            <a className="button has-background-primary" href="https://governwith.com/?utm_campaign=sample_assessment&utm_medium=application&utm_source=assess_governwith_com" target="_blank" rel="noreferrer" >Visit our website for more information</a>
          </Block>
          <hr />
          <Heading renderAs="h2" weight={"semibold"} size={4} className="is-size-5-mobile">Thank you!</Heading>
          <Block>
            We appreciate you taking the time to sample our assessment process today.
          </Block>
          <hr />
          <Block className="has-text-centered">
            Would you like to do this assessment again?
          </Block>
          <Block className="has-text-centered pb-5">
            <a className="button has-background-grey-lighter" onClick={resetForm}>Restart this Assessment</a>
          </Block>
        </Content>
      </Columns.Column>
    </Columns>
  )
}

export default Results;