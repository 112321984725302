import React, { useEffect, useState } from 'react';
import { Block, Columns, Form, Heading } from 'react-bulma-components';
import RadioWithClickableBox from '../Misc/RadioWithClickableBox';

const ESGQuestion2 = ({qController, setQController}) => {
  const [selectedValue, setSelectedValue] = useState(null);

  // scroll our question into view
  useEffect(() => {
    const element = document.getElementById('esgquestion2');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const onChange = (value) => {
    setSelectedValue(value);
    let updatedController = { ...qController };

    // record the response
    updatedController.responses.q2 = value;
    // determine score
    switch(value) {
      case 'Yes':
        updatedController.score.q2 = 100;
        break;
      default:
        updatedController.score.q2 = 0;
    }
    updatedController.display.q3 = true;
    setQController(updatedController);
  }

  return (
    <>
      <Block>
        <Heading className="is-size-4 mb-2">
          <a name="esgquestion2" id="esgquestion2">Question 2.</a>
        </Heading>
        <Block className="mb-2 has-text-weight-medium">
          Do you have an undergraduate or post-graduate qualification relevant to environmental issues such as climate change, the environment, social inclusion, gender equity, modern slavery, extreme events and/or similar?
        </Block>
        <Columns className="m-0 mb-1">
          <Columns.Column className="m-0 p-1 pb-2">
            <RadioWithClickableBox value="No" selectedValue={selectedValue} onChange={onChange} name="ESGQuestion2">
              No
            </RadioWithClickableBox>
          </Columns.Column>
          <Columns.Column className="m-0 p-1 pb-2">
            <RadioWithClickableBox value="Yes" selectedValue={selectedValue} onChange={onChange} name="ESGQuestion2">
              Yes
            </RadioWithClickableBox>
          </Columns.Column>
        </Columns>
      </Block>
      <hr />
    </>
  )
}

export default ESGQuestion2;