import React, { useEffect } from 'react';
import { Block, Button, Columns, Form } from 'react-bulma-components';
import { useMutation } from '@apollo/client';
import { UPDATE_ONE_RESPONSE_ESGUPDATE } from '../GraphQL/mutations';

const ESGSubmit = ({qController, evaluationResults, setEvaluationResults, progress, processId, setProgress}) => {
  const [updateResponse, {loading}] = useMutation(UPDATE_ONE_RESPONSE_ESGUPDATE);

  useEffect(() => {
    const element = document.getElementById('esgbutton');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  // calculate our scores
  const skills = qController.score.q1 * .25;
  const qualifications = qController.score.q2 * .25;
  const experience = qController.score.q3 * .5;

  let total = skills + qualifications + experience;
  if(skills <= 12) {
    total = skills;
  }

  const saveAnswers = async () => {
    try {
      const { error, data } = await updateResponse({
        variables: {
          query: { _id: processId }, // The query condition
          set: {
            esg_responses: {
              question_1: qController.responses.q1,
              question_2: qController.responses.q2,
              question_3: qController.responses.q3,
            },
            esg_results: {
              question_1: qController.score.q1,
              question_2: qController.score.q2,
              question_3: qController.score.q3,
              skills: skills,
              experience: experience,
              qualifications: qualifications,
              total: total,
            }
          },
        },
      });
      // Handle success or display a message
      if (data !== undefined && data !== null) {
        let updatedEvaluationResults = { ...evaluationResults };
        updatedEvaluationResults.esg = total;
        setEvaluationResults(updatedEvaluationResults);
        const nextProgress = progress + 1;
        localStorage.setItem('currentEvaluationResults', JSON.stringify(updatedEvaluationResults));
        localStorage.setItem('currentProgressId', parseInt(nextProgress,10));
        setProgress(parseInt(nextProgress,10));
      }
    } catch (error) {
      console.error('Error updating response:', error);
      // Handle error or display an error message
    }
  }

  return (
    <>
      <Block className="has-text-right">
        <Columns className="m-0 mb-1 is-mobile">
          <Columns.Column className="has-text-left">
            {loading ? "Saving Response..." : ""}
          </Columns.Column>
          <Columns.Column className="pr-0">
            <Form.Field>
              <Form.Control>
                <Button
                  color={loading ? "light" : "primary"}
                  type="submit"
                  disabled={loading}
                  onClick={saveAnswers}
                  id="esgbutton"
                >
                  Next &gt;&gt;
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
      </Block>
    </>
  )
}

export default ESGSubmit;