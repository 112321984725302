import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { INSERT_ONE_RESPONSE } from '../GraphQL/mutations';
import { Columns, Content, Block, Form, Button, Heading } from 'react-bulma-components';

const StartForm = ({progress,  setProgress,  setProcessId}) => {
  const [addResponse, {loading}] = useMutation(INSERT_ONE_RESPONSE);

  const startProcess = async () => {
    try {
      const { error, data } = await addResponse({
        variables: {
          data: {
            // Provide the data for the new response here
            contact: {},
            // Provide any other fields you need to add
          },
        },
      });
      // Handle success or display a message
      if (data !== undefined && data !== null) {
        // console.log('New Process ID:', data.insertOneResponse._id);
        localStorage.setItem('currentProcessId', data.insertOneResponse._id);
        localStorage.setItem('currentProgressId',parseInt(1,10));
        setProcessId(data.insertOneResponse._id);
        setProgress(parseInt(1,10));
      }
    } catch (error) {
      console.error('Error adding response:', error);
    }
  }

  return (
    <Columns className="m-1">
      <Columns.Column>
        <Content>
          <Heading weight={"semibold"} size={2} className="is-size-4-mobile">
            Welcome to a sample of two modules from the GovernWith Director Skills Assessment.
          </Heading>
          <Block>
            This assessment will only take a few minutes to complete and you will instantly receive your results and be able to compare yourself to the results of your peers collected by GovernWith during our 2022 - 2023 Benchmark period.
          </Block>
          <Block className="has-text-centered pb-6">
            <Form.Field>
              <Form.Control>
                <Button
                  color={loading ? "light" : "primary"}
                  type="submit"
                  onClick={startProcess}
                  disabled={loading}
                >
                  {loading ? "Loading the assessment, please wait" : "Click here to start your Assessment"}
                </Button>
              </Form.Control>
            </Form.Field>
          </Block>
        </Content>
      </Columns.Column>
    </Columns>
  )
}

export default StartForm;