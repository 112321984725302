import React from 'react';
import { Columns } from 'react-bulma-components';
import gwLogo from '../Assets/GovernWith_Terra.svg';
import gwTagline from '../Assets/GW-Tagline-Platinum.svg';
// import conveneLogo from '../Assets/Convene-logo_ie.svg';

const Header = () => {
  return (
    <Columns className="m-0 mb-1 is-mobile" id="pagetop" style={{'backgroundColor':'#223e4a'}}>
      <Columns.Column>
        <a href="https://governwith.com" target="_blank">
          <img
            alt="GovernWith.app"
            src={gwLogo}
            width="90%"
            style={{'maxWidth':'200px'}}
          />
        </a>
      </Columns.Column>
      <Columns.Column>
        <a href="https://governwith.com" target="_blank">
          <img
            alt="Effective Capable Contributing Governance"
            src={gwTagline}
            width="80%"
            style={{'maxWidth':'160px'}}
          />
        </a>
      </Columns.Column>
    </Columns>
  )
}

export default Header;