import React, { useState } from 'react';
import { Block, Columns, Form, Heading } from 'react-bulma-components';
import RadioWithClickableBox from '../Misc/RadioWithClickableBox';

const ESGQuestion1 = ({qController, setQController}) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const onChange = (value) => {
    setSelectedValue(value);
    let updatedController = { ...qController };

    // record the response
    updatedController.responses.q1 = value;
    // determine score
    switch(value) {
      case 'Expert':
        updatedController.score.q1 = 100;
        break;
      case 'Advanced':
        updatedController.score.q1 = 75;
        break;
      case 'Intermediate / appropriate':
        updatedController.score.q1 = 50;
        break;
      case 'Basic':
        updatedController.score.q1 = 25;
        break;
      default:
        updatedController.score.q1 = 0;
    }
    updatedController.display.q2 = true;
    setQController(updatedController);
  }

  return (
    <>
      <Block>
        <Heading className="is-size-4 mb-2">
          <a name="esgquestion1" id="esgquestion1">Question 1.</a>
        </Heading>
        <Block className="mb-2 has-text-weight-medium">
          I would classify my understanding of Environmental, Social and Governance (ESG) in relation to environmental issues, such as, climate change, extreme events, social inclusion and other “people matters”, such as, gender equity, modern slavery, working with Aboriginals and Torres Strait Islanders and so on, as
        </Block>
        <Columns className="m-0 mb-1">
          <Columns.Column className="m-0 p-1 pb-2">
            <RadioWithClickableBox value="Poor" selectedValue={selectedValue} onChange={onChange} name="ESGQuestion1">
              Poor
              <Block className="ml-5 is-size-7 is-italic">
                Requires significant development
              </Block>
            </RadioWithClickableBox>
          </Columns.Column>
          <Columns.Column className="m-0 p-1 pb-2">
            <RadioWithClickableBox value="Basic" selectedValue={selectedValue} onChange={onChange} name="ESGQuestion1">
              Basic
              <Block className="ml-5 is-size-7 is-italic">
                Basic functional understanding of the area
              </Block>
            </RadioWithClickableBox>
          </Columns.Column>
          <Columns.Column className="m-0 p-1 pb-2">
            <RadioWithClickableBox value="Intermediate / appropriate" selectedValue={selectedValue} onChange={onChange} name="ESGQuestion1">
              Intermediate / appropriate
              <Block className="ml-5 is-size-7 is-italic">
                Reasonable and appropriate understanding of the concept for my role
              </Block>
            </RadioWithClickableBox>
          </Columns.Column>
          <Columns.Column className="m-0 p-1 pb-2">
            <RadioWithClickableBox value="Advanced" selectedValue={selectedValue} onChange={onChange} name="ESGQuestion1">
              Advanced
              <Block className="ml-5 is-size-7 is-italic">
                Thorough understanding of the concept/area
              </Block>
            </RadioWithClickableBox>
          </Columns.Column>
          <Columns.Column className="m-0 p-1 pb-2">
            <RadioWithClickableBox value="Expert" selectedValue={selectedValue} onChange={onChange} name="ESGQuestion1">
              Expert
              <Block className="ml-5 is-size-7 is-italic">
                Exceptional understanding
              </Block>
            </RadioWithClickableBox>
          </Columns.Column>
        </Columns>
      </Block>
      <hr />
    </>
  )
}

export default ESGQuestion1;