import React, {
  useState, useEffect
} from 'react';
import * as Realm from "realm-web";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { APP_ID } from '../constants';

const app = new Realm.App(APP_ID);

// Gets a valid Realm user access token to authenticate requests - for anonymous access
async function getValidAccessToken() {
  if (!app.currentUser) {
    await app.logIn(Realm.Credentials.anonymous());
  } else {
    await app.currentUser.refreshCustomData();
  }
  return app.currentUser.accessToken;
}

// Configure the ApolloClient to connect to your app's GraphQL endpoint
const createRealmApolloClient = new ApolloClient({
  link: new HttpLink({
    uri: `https://ap-southeast-2.aws.realm.mongodb.com/api/client/v2.0/app/${app.id}/graphql`,
    fetch: async (uri, options) => {
      const accessToken = await getValidAccessToken();
      options.headers.Authorization = `Bearer ${accessToken}`;
      return fetch(uri, options);
    },
  }),
  cache: new InMemoryCache(),
});

export default function RealmApolloProvider({ children }) {
  const [client, setClient] = useState(createRealmApolloClient);
  useEffect(() => {
    setClient(createRealmApolloClient);
  }, [app]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}