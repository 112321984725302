import React, { useEffect, useState } from 'react';
import { Block, Columns, Form, Heading } from 'react-bulma-components';
import RadioWithClickableBox from '../Misc/RadioWithClickableBox';

const CEQuestion5 = ({qController, setQController}) => {
  const [selectedValue, setSelectedValue] = useState(null);

  // scroll our question into view
  useEffect(() => {
    const element = document.getElementById('cequestion5');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const onChange = (value) => {
    setSelectedValue(value);
    let updatedController = { ...qController };

    // record the response
    updatedController.responses.q5 = value;
    // determine score
    switch(value) {
      case 'Yes':
        updatedController.score.q5 = 100;
        break;
      default:
        updatedController.score.q5 = 0;
    }
    updatedController.display.q6 = true;
    setQController(updatedController);
  }

  return (
    <>
      <Block>
        <Heading className="is-size-4 mb-2">
          <a name="cequestion5" id="cequestion5">Question 5.</a>
        </Heading>
        <Block className="mb-2 has-text-weight-medium">
          Do you have a diploma or undergraduate degree in Computer Science, IT or equivalent?
        </Block>
        <Columns className="m-0 mb-1">
          <Columns.Column className="m-0 p-1 pb-2">
            <RadioWithClickableBox value="No" selectedValue={selectedValue} onChange={onChange} name="CEQuestion5">
              No
            </RadioWithClickableBox>
          </Columns.Column>
          <Columns.Column className="m-0 p-1 pb-2">
            <RadioWithClickableBox value="Yes" selectedValue={selectedValue} onChange={onChange} name="CEQuestion5">
              Yes
            </RadioWithClickableBox>
          </Columns.Column>
        </Columns>
      </Block>
      <hr />
    </>
  )
}

export default CEQuestion5;