import React, { useEffect, useState } from 'react';
import { Box, Block, Columns, Form, Heading } from 'react-bulma-components';
import RadioWithClickableBox from '../Misc/RadioWithClickableBox';

const CEQuestion1 = ({qController, setQController}) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const onChange = (value) => {
    setSelectedValue(value);
    let updatedController = { ...qController };

    // record the response
    updatedController.responses.q1 = value;
    // determine score
    switch(value) {
      case 'Expert':
        updatedController.score.q1 = 100;
        break;
      case 'Advanced':
        updatedController.score.q1 = 75;
        break;
      case 'Intermediate / appropriate':
        updatedController.score.q1 = 50;
        break;
      case 'Basic':
        updatedController.score.q1 = 25;
        break;
      default:
        updatedController.score.q1 = 0;
    }
    updatedController.display.q2 = true;
    setQController(updatedController);
  }

  return (
   <>
    <Block>
      <Heading className="is-size-4 mb-2">
        <a name="cequestion1" id="cequestion1">Question 1.</a>
      </Heading>
      <Block className="mb-2 has-text-weight-medium">
        I believe my understanding of ICT Strategy, including being able to identify the organisation’s key risks, is
      </Block>
      <Columns className="m-0 mb-1">
        <Columns.Column className="m-0 p-1 pb-2">
          <RadioWithClickableBox value="Poor" selectedValue={selectedValue} onChange={onChange} name="CEQuestion1">
            Poor
            <Block className="ml-5 is-size-7 is-italic">
              Requires significant development
            </Block>
          </RadioWithClickableBox>
        </Columns.Column>
        <Columns.Column className="m-0 p-1 pb-2">
          <RadioWithClickableBox value="Basic" selectedValue={selectedValue} onChange={onChange} name="CEQuestion1">
            Basic
            <Block className="ml-5 is-size-7 is-italic">
              Basic functional understanding of the area
            </Block>
          </RadioWithClickableBox>
        </Columns.Column>
        <Columns.Column className="m-0 p-1 pb-2">
          <RadioWithClickableBox value="Intermediate / appropriate" selectedValue={selectedValue} onChange={onChange} name="CEQuestion1">
            Intermediate / appropriate
            <Block className="ml-5 is-size-7 is-italic">
              Reasonable and appropriate understanding of the concept for my role
            </Block>
          </RadioWithClickableBox>
        </Columns.Column>
        <Columns.Column className="m-0 p-1 pb-2">
          <RadioWithClickableBox value="Advanced" selectedValue={selectedValue} onChange={onChange} name="CEQuestion1">
            Advanced
            <Block className="ml-5 is-size-7 is-italic">
              Thorough understanding of the concept/area
            </Block>
          </RadioWithClickableBox>
        </Columns.Column>
        <Columns.Column className="m-0 p-1 pb-2">
          <RadioWithClickableBox value="Expert" selectedValue={selectedValue} onChange={onChange} name="CEQuestion1">
            Expert
            <Block className="ml-5 is-size-7 is-italic">
              Exceptional understanding
            </Block>
          </RadioWithClickableBox>
        </Columns.Column>
      </Columns>
    </Block>
     <hr />
  </>
  )
}

export default CEQuestion1;