import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useForm, Controller } from 'react-hook-form';
import { Icon, Form, Button, Content, Columns, Block } from 'react-bulma-components';
import { FaUser, FaEnvelope, FaPhone, FaBuilding, FaUserEdit, FaRegClipboard, FaIndustry } from 'react-icons/fa';
import ProgressBar from '../ProgressBar/ProgressBar';
import { UPDATE_ONE_RESPONSE_DETAILS } from '../GraphQL/mutations';

const DetailsForm = ({progress, setProgress,  processId}) => {
  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      company: "",
      role: "",
      governancerole: "",
      sector: "",
    },
  });

  const [updateResponse, {loading}] = useMutation(UPDATE_ONE_RESPONSE_DETAILS);

  const onSubmit = async (formData) => {
    try {
      const { data } = await updateResponse({
        variables: {
          query: { _id: processId }, // The query condition
          set: {
            contact: {
              email: formData.email,
              first_name: formData.firstName,
              governance_role: formData.governancerole,
              jobtitle: formData.role,
              last_name: formData.lastName,
              mobile: formData.phone,
              organisation: formData.company,
              sector: formData.sector,
            },
          },
        },
      });
      // Handle success or display a message
      if (data !== undefined && data !== null) {
        // console.log(data);
        const nextProgress = progress + 1;
        localStorage.setItem('currentProgressId',parseInt(nextProgress,10));
        setProgress(parseInt(nextProgress,10));
      }
    } catch (error) {
      console.error('Error updating response:', error);
      // Handle error or display an error message
    }
  }

  const currentProgress = (((progress -1) / 4) * 100);

  useEffect(() => {
    const element = document.getElementById('pagetop');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Columns className="m-1">
      <Columns.Column>
        <Content>
          <ProgressBar progress={currentProgress} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Block>
              Please complete your details to view your results and enter the draw for the Ultimate Governance package.
            </Block>
            <Block className="mb-2 is-size-7">
              * Required fields
            </Block>
            <Form.Field>
              <Form.Control>
                <Icon align="left">
                  <FaUser />
                </Icon>
                <Controller
                  name="firstName"
                  control={control}
                  rules= {{ required: true }}
                  render={({ field }) =>
                    <Form.Input {...field} ref={null} placeholder="* First Name" />
                  }
                />
              </Form.Control>
              {errors.firstName && <p className="help is-danger">Your first name is required</p>}
            </Form.Field>

            <Form.Field>
              <Form.Control>
                <Icon align="left">
                  <FaUser />
                </Icon>
                <Controller
                  name="lastName"
                  control={control}
                  rules= {{ required: true }}
                  render={({ field }) =>
                    <Form.Input {...field} ref={null} placeholder="* Last Name" />
                  }
                />
              </Form.Control>
              {errors.lastName && <p className="help is-danger">Your last name is required</p>}
            </Form.Field>

            <Form.Field>
              <Form.Control>
                <Icon align="left">
                  <FaEnvelope />
                </Icon>
                <Controller
                  name="email"
                  control={control}
                  rules= {{ required: true, pattern: /^\S+@\S+$/i }}
                  render={({ field }) =>
                    <Form.Input {...field} ref={null} placeholder="* Email Address" />
                  }
                />
              </Form.Control>
              {errors.email && <p className="help is-danger">A valid email is required</p>}
            </Form.Field>

            <Form.Field>
              <Form.Control>
                <Icon align="left">
                  <FaPhone />
                </Icon>
                <Controller
                  name="phone"
                  control={control}
                  rules= {{
                    required: true,
                    pattern: {
                      value: /^(?:\+?61|0)?\d{8,10}$/,
                      message: "Invalid Australian phone number"
                    }
                  }}
                  render={({ field }) =>
                    <Form.Input {...field} ref={null} placeholder="* Mobile number" />
                  }
                />
              </Form.Control>
              {errors.phone && <p className="help is-danger">A valid mobile/phone number is required</p>}
            </Form.Field>

            <Form.Field>
              <Form.Control>
                <Icon align="left">
                  <FaBuilding />
                </Icon>
                <Controller
                  name="company"
                  control={control}
                  rules= {{ required: true }}
                  render={({ field }) =>
                    <Form.Input {...field} ref={null} placeholder="* Company" />
                  }
                />
              </Form.Control>
              {errors.company && <p className="help is-danger">Company name is required</p>}
            </Form.Field>

            <Form.Field>
              <Form.Control>
                <Icon align="left">
                  <FaUserEdit />
                </Icon>
                <Controller
                  name="role"
                  control={control}
                  rules= {{ required: true }}
                  render={({ field }) =>
                    <Form.Input {...field} ref={null} placeholder="* Job Title" />
                  }
                />
              </Form.Control>
              {errors.role && <p className="help is-danger">Your Job Title is required</p>}
            </Form.Field>

            <Form.Field>
              <Form.Control className="is-expanded">
                <Icon align="left">
                  <FaRegClipboard />
                </Icon>
                <Controller
                  name="governancerole"
                  control={control}
                  rules= {{ required: true }}
                  render={({ field }) =>
                    <Form.Select {...field} ref={null} placeholder="* Governance Role" className="is-fullwidth">
                      <option value="" disabled="">* Governance Role</option>
                      <option value="Chairperson">Chairperson</option>
                      <option value="Director (Sitting)">Director (Sitting)</option>
                      <option value="Director (Aspiring)">Director (Aspiring)</option>
                      <option value="CEO">CEO</option>
                      <option value="School Principal">School Principal</option>
                      <option value="Executive Assistant (Board Support)">Executive Assistant</option>
                      <option value="Company Secretary (Board Support)">Company Secretary</option>
                      <option value="School Business Manager (Board Support)">School Business Manager</option>
                      <option value="Executive (Board Support)">Executive</option>
                      <option value="Other (Board Support)">Other</option>
                    </Form.Select>
                  }
                />
              </Form.Control>
              {errors.governancerole && <p className="help is-danger">Please select your Governance Role</p>}
            </Form.Field>

            <Form.Field>
              <Form.Control className="is-expanded">
                <Icon align="left">
                  <FaIndustry />
                </Icon>
                <Controller
                  name="sector"
                  control={control}
                  rules= {{ required: true }}
                  render={({ field }) =>
                    <Form.Select {...field} ref={null} placeholder="* Industry Sector" className="is-fullwidth">
                      <option value="" disabled="">* Industry Sector</option>
                      <option value="Health">Health</option>
                      <option value="Human Services">Human Services</option>
                      <option value="Aged Care">Aged Care</option>
                      <option value="Education">Education</option>
                      <option value="Community">Community</option>
                      <option value="Government">Government</option>
                      <option value="Finance">Finance</option>
                      <option value="Other">Other</option>
                    </Form.Select>
                  }
                />
              </Form.Control>
              {errors.sector && <p className="help is-danger">Please select your Industry Sector</p>}
            </Form.Field>

            <Block className="has-text-right">
              <Columns className="m-0 mb-1 is-mobile">
                <Columns.Column className="has-text-left">
                  {loading ? "Saving Response..." : ""}
                </Columns.Column>
                <Columns.Column>
                  <Form.Field>
                    <Form.Control>
                      <Button
                        color={loading ? "light" : "primary"}
                        type="submit"
                        disabled={loading}
                      >
                        Submit
                      </Button>
                    </Form.Control>
                  </Form.Field>
                </Columns.Column>
              </Columns>
            </Block>
          </form>
        </Content>
      </Columns.Column>
    </Columns>
  );
};

export default DetailsForm;