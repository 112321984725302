import React, { useState } from 'react';
import { Block, Columns, Form } from 'react-bulma-components';
import RadioWithClickableBox from '../Misc/RadioWithClickableBox';

const QualifyQ1A2 = ({qController, setQController}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const onChange = (value) => {
    setSelectedValue(value);
    let updatedController = { ...qController };
    updatedController.responses.q1a2 = value;
    updatedController.display.submit= true;
    setQController(updatedController);
  }

  return (
    <Block>
      <Block className="mb-0 has-text-weight-medium">
        Was it internally or externally led?
      </Block>
      <Columns className="m-0 mb-1 is-mobile">
        <Columns.Column className="pl-0">
          <RadioWithClickableBox value="Internally Led" selectedValue={selectedValue} onChange={onChange} name="QualifyQ1A2">
            Internally Led
          </RadioWithClickableBox>
        </Columns.Column>
        <Columns.Column className="pr-0">
          <RadioWithClickableBox value="Externally Led" selectedValue={selectedValue} onChange={onChange} name="QualifyQ1A2">
            Externally Led
          </RadioWithClickableBox>
        </Columns.Column>
      </Columns>
      <hr />
    </Block>
  )
}

export default QualifyQ1A2;