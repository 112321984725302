import React, { useEffect, useState } from 'react';
import ProgressBar from '../ProgressBar/ProgressBar';
import { Columns, Content, Block } from 'react-bulma-components';
import QualifyQ1 from './QualifyQ1';
import QualifyQ1A1 from './QualifyQ1A1';
import QualifyQ1A2 from './QualifyQ1A2';
import QualifyQ1B1 from './QualifyQ1B1';
import QualifySubmit from './QualifySubmit';

const GWQualifyForm = ({progress, setProgress, processId}) => {
  const currentProgress = (((progress -1) / 4) * 100);

  const [qController, setQController] = useState(
    {
      display: {
        q1: true,
        q1a1: false,
        q1a2: false,
        q1b1: false,
        submit: false
      },
      responses: {
        q1: null,
        q1a1: null,
        q1a2: null,
        q1b1: null
      }
    }
  )

  useEffect(() => {
    const element = document.getElementById('pagetop');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Columns className="m-1">
      <Columns.Column>
        <Content>
          <ProgressBar progress={currentProgress} />
            <Block>
              {qController.display.q1 && <QualifyQ1 qController={qController} setQController={setQController}/>}
              {qController.display.q1a1 && <QualifyQ1A1 qController={qController} setQController={setQController}/>}
              {qController.display.q1a2 && <QualifyQ1A2 qController={qController} setQController={setQController}/>}
              {qController.display.q1b1 && <QualifyQ1B1 qController={qController} setQController={setQController}/>}
              {qController.display.submit && <QualifySubmit progress={progress} responses={qController.responses} setProgress={setProgress} processId={processId}  />}
            </Block>
        </Content>
      </Columns.Column>
    </Columns>
  )
}

export default GWQualifyForm;