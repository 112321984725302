import React, { useState } from 'react';
import { Block, Columns, Form } from 'react-bulma-components';
import { Rating } from 'react-simple-star-rating'

const QualifyQ1A1 = ({qController, setQController}) => {
  const [rating, setRating] = useState(0)

  // Catch Rating value
  const handleRating = (rate: number) => {
    setRating(rate);

    let updatedController = { ...qController };

    updatedController.display.q1a2 = true;
    updatedController.responses.q1a1 = rate;

    setQController(updatedController);
    // other logic
  }

  // console.log(rating);

  return (
    <>
      <Block className="mb-0 has-text-weight-medium">
        How assured are you that it has identified the greatest risks and provided a clear plan of action to mitigate them?
      </Block>
      <Block className="mt-2 has-text-centered">
        <Rating
          onClick={handleRating}
        />
        <br/>
        <Block className="is-italic">
          (1 star - not assured, 5 stars - very assured)
        </Block>
      </Block>
      <hr />
    </>
  )
}

export default QualifyQ1A1;