import React from 'react';
import { Block, Progress } from 'react-bulma-components';

const ProgressBar = ({progress}) => {

  return (
    <div>
      <Block className="is-size-7 mb-1">
        Your progress to your results: {progress}% complete
      </Block>
      <Block className="mb-2">
        <Progress max={100} value={progress} />
      </Block>
    </div>
  )
}

export default ProgressBar;