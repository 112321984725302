import React, { useEffect, useState } from 'react';
import ProgressBar from '../ProgressBar/ProgressBar';
import { Columns, Content, Heading } from 'react-bulma-components';
import ESGIntroduction from './ESGIntroduction';
import ESGQuestion1 from './ESGQuestion1';
import ESGQuestion2 from './ESGQuestion2';
import ESGQuestion3 from './ESGQuestion3';
import ESGSubmit from './ESGSubmit';

const ESGEvaluation = ({progress,  setProgress,  processId, evaluationResults, setEvaluationResults}) => {
  const currentProgress = (((progress -1) / 4) * 100);

  const [qController, setQController] = useState(
    {
      display: {
        q1: true,
        q2: false,
        q3: false,
        submit: false
      },
      responses: {
        q1: null,
        q2: null,
        q3: null
      },
      score: {
        q1: 0,
        q2: 0,
        q3: 0
      }
    }
  )

  // console.log('qController', qController);
  useEffect(() => {
    const element = document.getElementById('pagetop');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <Columns className="m-1">
      <Columns.Column>
        <Content>
          <ProgressBar progress={currentProgress} />
          <Heading weight={"semibold"} size={2} className="is-size-4-mobile">Environmental, Social and Governance (ESG)</Heading>
          <Heading renderAs="h2" weight={"normal"} size={4} subtitle={true} className="is-size-5-mobile mb-0">Director Skills Assessment</Heading>
          <Heading renderAs="h3" weight={"normal"} size={6} subtitle={true} className="is-size-7-mobile mt-1">3 Questions</Heading>
          <ESGIntroduction />
          {qController.display.q1 && <ESGQuestion1 qController={qController} setQController={setQController} />}
          {qController.display.q2 && <ESGQuestion2 qController={qController} setQController={setQController} />}
          {qController.display.q3 && <ESGQuestion3 qController={qController} setQController={setQController} />}
          {qController.display.submit && <ESGSubmit qController={qController} evaluationResults={evaluationResults} setEvaluationResults={setEvaluationResults} progress={progress} processId={processId} setProgress={setProgress} />}
        </Content>
      </Columns.Column>
    </Columns>
  )
}

export default ESGEvaluation;