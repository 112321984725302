import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Block, Box, Button, Columns, Form, Heading } from 'react-bulma-components';

const ESGQuestion3 = ({qController, setQController}) => {
  // const [selectedValues, setSelectedValues] = useState([]);
  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      'Climate Change': false,
      'Extreme Events': false,
      'Social Inclusion': false,
      'Gender Equity': false,
      'Modern Slavery': false,
      'Working with Aboriginals and Torres Strait Islanders': false,
      'Other Minority and Vunerable Groups': false
    },
  });

  // scroll our question into view
  useEffect(() => {
    const element = document.getElementById('esgquestion3');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const onSubmit = (formData) => {
    // Extract true elements into a string list
    const trueElementsList = Object.keys(formData).filter(key => formData[key] === true).join(', ');
    // Count the number of true elements
    const trueElementsCount = Object.values(formData).filter(value => value === true).length;
    // update the state
    let updatedController = { ...qController };
    // record the response
    updatedController.responses.q3 = trueElementsList;
    // determine score
    updatedController.score.q3 = Math.round((100 / 7) * trueElementsCount);
    updatedController.display.submit = true;
    setQController(updatedController);
  }

  return (
    <>
      <Block>
        <Heading className="is-size-4 mb-2">
          <a name="esgquestion1" id="esgquestion3">Question 3.</a>
        </Heading>
        <Block className="mb-2 has-text-weight-medium">
          Do you have relevant experience through Lived Experience or as a Consultant, Volunteer, Staff, Executive, Manager or Director in the field of?
        </Block>
        <Block className="mb-1">
          Please select all that apply to you:
        </Block>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Columns className="m-0 mb-1">
            <Columns.Column className="m-0 p-1 pb-2">
              <Controller
                name="Climate Change"
                control={control}
                rules= {{ required: false }}
                render={({ field }) =>
                  <label>
                    <Box className={field.value ? "p-3 has-background-grey-lighter" : "p-3 has-background-white-bis"}>
                      <Form.Checkbox
                        {...field} ref={null}
                      >
                        &nbsp;Climate Change
                      </Form.Checkbox>
                    </Box>
                  </label>
                }
              />
            </Columns.Column>
            <Columns.Column className="m-0 p-1 pb-2">
              <Controller
                name="Extreme Events"
                control={control}
                rules= {{ required: false }}
                render={({ field }) =>
                  <label>
                    <Box className={field.value ? "p-3 has-background-grey-lighter" : "p-3 has-background-white-bis"}>
                      <Form.Checkbox
                        {...field} ref={null}
                      >
                        &nbsp;Extreme Events
                      </Form.Checkbox>
                    </Box>
                  </label>
                }
              />
            </Columns.Column>
            <Columns.Column className="m-0 p-1 pb-2">
              <Controller
                name="Social Inclusion"
                control={control}
                rules= {{ required: false }}
                render={({ field }) =>
                  <label>
                    <Box className={field.value ? "p-3 has-background-grey-lighter" : "p-3 has-background-white-bis"}>
                      <Form.Checkbox
                        {...field} ref={null}
                      >
                        &nbsp;Social Inclusion
                      </Form.Checkbox>
                    </Box>
                  </label>
                }
              />
            </Columns.Column>
            <Columns.Column className="m-0 p-1 pb-2">
              <Controller
                name="Gender Equity"
                control={control}
                rules= {{ required: false }}
                render={({ field }) =>
                  <label>
                    <Box className={field.value ? "p-3 has-background-grey-lighter" : "p-3 has-background-white-bis"}>
                      <Form.Checkbox
                        {...field} ref={null}
                      >
                        &nbsp;Gender Equity
                      </Form.Checkbox>
                    </Box>
                  </label>
                }
              />
            </Columns.Column>
            <Columns.Column className="m-0 p-1 pb-2">
              <Controller
                name="Modern Slavery"
                control={control}
                rules= {{ required: false }}
                render={({ field }) =>
                  <label>
                    <Box className={field.value ? "p-3 has-background-grey-lighter" : "p-3 has-background-white-bis"}>
                      <Form.Checkbox
                        {...field} ref={null}
                      >
                        &nbsp;Modern Slavery
                      </Form.Checkbox>
                    </Box>
                  </label>
                }
              />
            </Columns.Column>
            <Columns.Column className="m-0 p-1 pb-2">
              <Controller
                name="Working with Aboriginals and Torres Strait Islanders"
                control={control}
                rules= {{ required: false }}
                render={({ field }) =>
                  <label>
                    <Box className={field.value ? "p-3 has-background-grey-lighter" : "p-3 has-background-white-bis"}>
                      <Form.Checkbox
                        {...field} ref={null}
                      >
                        &nbsp;Working with Aboriginals and Torres Strait Islanders
                      </Form.Checkbox>
                    </Box>
                  </label>
                }
              />
            </Columns.Column>
            <Columns.Column className="m-0 p-1 pb-2">
              <Controller
                name="Other Minority and Vunerable Groups"
                control={control}
                rules= {{ required: false }}
                render={({ field }) =>
                  <label>
                    <Box className={field.value ? "p-3 has-background-grey-lighter" : "p-3 has-background-white-bis"}>
                      <Form.Checkbox
                        {...field} ref={null}
                      >
                        &nbsp;Other Minority and Vunerable Groups
                      </Form.Checkbox>
                    </Box>
                  </label>
                }
              />
            </Columns.Column>
          </Columns>
          <Block className="has-text-right">
            <Columns className="m-0 mb-1 is-mobile">
              <Columns.Column>
                <Form.Field>
                  <Form.Control>
                    <Button
                      color="primary"
                      type="submit"
                    >
                      Submit Choices
                    </Button>
                  </Form.Control>
                </Form.Field>
              </Columns.Column>
            </Columns>
          </Block>
        </form>
      </Block>
      <hr />
    </>
  )
};

export default ESGQuestion3;