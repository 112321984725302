import React, { useEffect } from 'react';
import { Block, Button, Columns, Form } from 'react-bulma-components';
import { useMutation } from '@apollo/client';
import { UPDATE_ONE_RESPONSE_CYBERUPDATE } from '../GraphQL/mutations';

const CESubmit = ({qController, evaluationResults, setEvaluationResults, progress, processId, setProgress}) => {
  const [updateResponse, {loading}] = useMutation(UPDATE_ONE_RESPONSE_CYBERUPDATE);

  useEffect(() => {
    const element = document.getElementById('cyberbutton');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  // calculate our scores
  const skills = (((qController.score.q1 + qController.score.q2 + qController.score.q3 + qController.score.q4) / 4) * .25);
  const qualifications = (((qController.score.q5 + qController.score.q6) / 2) * .25);
  const experience = (((qController.score.q7 + qController.score.q8) / 2) * .5);

  let total = skills + qualifications + experience;
  if(skills <= 12) {
    total = skills;
  }

  // console.log('skills', skills);
  // console.log('qualifications', qualifications);
  // console.log('experience', experience);
  // console.log('total', total);

  const saveAnswers = async () => {
    try {
      const { error, data } = await updateResponse({
        variables: {
          query: { _id: processId }, // The query condition
          set: {
            cyber_responses: {
              question_1: qController.responses.q1,
              question_2: qController.responses.q2,
              question_3: qController.responses.q3,
              question_4: qController.responses.q4,
              question_5: qController.responses.q5,
              question_6: qController.responses.q6,
              question_7: qController.responses.q7,
              question_8: qController.responses.q8,
            },
            cyber_results: {
              question_1: qController.score.q1,
              question_2: qController.score.q2,
              question_3: qController.score.q3,
              question_4: qController.score.q4,
              question_5: qController.score.q5,
              question_6: qController.score.q6,
              question_7: qController.score.q7,
              question_8: qController.score.q8,
              skills: skills,
              experience: experience,
              qualifications: qualifications,
              total: total,
            }
          },
        },
      });
      // Handle success or display a message
      if (data !== undefined && data !== null) {
        let updatedEvaluationResults = { ...evaluationResults };
        updatedEvaluationResults.cyber = total;
        setEvaluationResults(updatedEvaluationResults);
        const nextProgress = progress + 1;
        localStorage.setItem('currentEvaluationResults', JSON.stringify(updatedEvaluationResults));
        localStorage.setItem('currentProgressId', parseInt(nextProgress,10));
        setProgress(parseInt(nextProgress,10));
      }
    } catch (error) {
      console.error('Error updating response:', error);
      // Handle error or display an error message
    }
  }

  return(
    <Block className="has-text-right">
      <Columns className="m-0 mb-1 is-mobile">
        <Columns.Column className="has-text-left">
          {loading ? "Saving Response..." : ""}
        </Columns.Column>
        <Columns.Column className="pr-0">
          <Form.Field>
            <Form.Control>
              <Button
                color={loading ? "light" : "primary"}
                type="submit"
                disabled={loading}
                onClick={saveAnswers}
                id="cyberbutton"
              >
                Next &gt;&gt;
              </Button>
            </Form.Control>
          </Form.Field>
        </Columns.Column>
      </Columns>
    </Block>
  )
}

export default CESubmit;