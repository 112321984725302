import React, { useState } from 'react';
import { Block, Columns, Form } from 'react-bulma-components';
import RadioWithClickableBox from '../Misc/RadioWithClickableBox';

const QualifyQ1 = ({qController, setQController}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const onChange = (value) => {
    setSelectedValue(value);
    let updatedController = { ...qController };

    if (value === 'yes') {
      updatedController.responses.q1 = 'yes';
      updatedController.responses.q1b1 = null;
      updatedController.display.q1a1 = true;
      updatedController.display.q1a2 = false;
      updatedController.display.q1b1 = false;
      updatedController.display.submit = false;
    } else {
      updatedController.responses.q1 = 'no';
      updatedController.responses.q1a1 = null;
      updatedController.responses.q1a2 = null;
      updatedController.display.q1a1 = false;
      updatedController.display.q1a2 = false;
      updatedController.display.q1b1 = true;
      updatedController.display.submit = false;
    }
    setQController(updatedController);
  };

  return(
    <>
      <Block className="mt-5">
        <Block className="mb-2 has-text-weight-medium">
          Have you conducted a Board Governance Review in 2023?
        </Block>
        <Columns className="m-0 mb-1 is-mobile">
          <Columns.Column className="pl-0">
            <RadioWithClickableBox value="yes" selectedValue={selectedValue} onChange={onChange} name="QualifyQ1">
              Yes
            </RadioWithClickableBox>
          </Columns.Column>
          <Columns.Column className="pr-0">
            <RadioWithClickableBox value="no" selectedValue={selectedValue} onChange={onChange} name="QualifyQ1">
              No
            </RadioWithClickableBox>
          </Columns.Column>
        </Columns>
      </Block>
      <hr />
    </>
  )
}

export default QualifyQ1;